import React, {useEffect} from 'react';
import styles from './App.module.scss';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import {Settings} from './components/settings/settings';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import {InterfaceScreen} from './components/interface-screen/interface-screen';
import {AuthPage} from './components/auth-page/auth-page';
import {getSettings} from './utils/settings/get-settings';
import {saveSettings} from './utils/settings/save-settings';
import {defaultSettings} from './utils/settings/default-settings';

function App() {

	const fullScreenHandle = useFullScreenHandle();

	const {pathname} = useLocation();

	const navigate = useNavigate();

	useEffect( () => {
		if ( !document.getElementById( 'babylonScript' ) ) {
			const script = document.createElement( 'script' );
			script.id = 'babylonScript';
			script.type = 'text/javascript';
			script.src = 'js/bundleName.js';
			script.defer = true;
			document.head.appendChild( script );
		}
		// if no game settings found in localStorage default ones will be used
		!getSettings().length && saveSettings( defaultSettings );
		// hotkey redirect to the /settings
	}, [] );

	return (
		<FullScreen handle={fullScreenHandle}>
			<div className={styles['game-wrapper']}>
				<Routes>
					<Route path="/" element={<InterfaceScreen fullScreenHandle={fullScreenHandle}/>}>
						<Route path="/auth" element={<AuthPage/>}/>
						<Route path="/game" element={<></>}/>
						<Route path="/settings" element={<Settings/>}/>
					</Route>
				</Routes>
			</div>
		</FullScreen>
	);
}

export default App;
