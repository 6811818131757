import {Client, Session} from '@heroiclabs/nakama-js';

export const getSession = async (): Promise<null | Session> => {
	// checking session presence
	if ( !localStorage.getItem( 'nakamaSession' ) ) {
		console.warn( 'No session found' );
		return null;
	}

	let session: null | Session = null;

	try {
		session = JSON.parse( localStorage.getItem( 'nakamaSession' )! );
	} catch ( e ) {
		console.warn( 'Invalid session format' );
		return null;
	}


	// checking tokens presence
	if ( !session ) return null;

	if ( !( 'token' in session ) || !( 'refresh_token' in session ) ) {
		console.warn( 'No session tokens found' );
		return null;
	}

	// getting props from session object
	let {token, refresh_token} = session;

	// restoring session instance
	try {
		session = Session.restore( token, refresh_token );
	} catch ( e ) {
		// invalid tokens
		console.warn( 'Invalid session tokens' );
		return null;
	}

	const currentTime = Math.floor( new Date().getTime() / 1000 );
	const expiryTimeSpan = 10;

	// checking if session, or it's refresh_token expired or expires in less than 10 seconds
	if ( session.isexpired( currentTime + expiryTimeSpan ) ) {
		if ( session.isrefreshexpired( currentTime + expiryTimeSpan ) ) {
			console.warn( 'Session expired - not refreshable, re-auth required' );
			return null;
		} else {
			// expired, but refreshable session
			const client = new Client(
				undefined,
				window.location.hostname,
				undefined,
				true
			);

			return client.sessionRefresh( session )
				.then( refreshedSession => {
					console.info( 'Session expired - performed refresh' );
					return refreshedSession;
				} )
				.catch( () => {
					console.warn( 'Session expired - refresh failed' );
					return null;
				} );
		}
	} else {
		console.info( 'Session is OK' );
		return session;
	}
};
