export const en = {
	headers: {
		menu: 'Main Menu',
		settings: 'Settings'
	},
	buttons: {
		login: 'Log In',
		guest: 'Guest',
		signup: 'Sign Up',
		close: 'Close',
		cancel: 'Cancel',
		yes: 'Yes',
		no: 'No',
		mainMenu: 'Main Menu',
		startGame: 'Start',
		playAgain: 'Play Again',
		loading: 'Loading',
		resetSettings: 'Reset',
		saveSettings: 'Save & Quit'
	},
	placeholders: {
		loginEmail: 'Login / E-mail',
		username: 'Username',
		email: 'E-mail',
		password: 'Password',
		repeatPassword: 'Repeat Password'
	},
	settings: {
		lives: 'Amount of lives',
		time: 'Game duration',
		maxCubes: 'Maximum number of blocks',
		minCubes: 'Minimum number of blocks',
		durability: 'Maximum block durability',
		startSpeed: 'Start ball speed',
		maxSpeed: 'Maximum ball speed',
		bonusChance: 'Bonus drop chance',
		bonusCount: 'Maximum number of bonuses',
		debuffChance: 'Debuff drop chance',
		debuffCount: 'Maximum number of debuffs',
		rebound: 'Ball rebound rate',
		paddleSize: 'Paddle size',
		speedBonus: 'The percentage value by which the speed of the ball increases',
		showBonus: 'Show/hide bonuses',
		advancedGen: 'Advanced generation',
		difficulty: 'Difficulty'
	},
	game: {
		matchmaking: 'Finding Match',
		pause: 'Game Paused',
		finishGame: 'Do you want to finish the game?'
	}
};
