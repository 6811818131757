import styles from './controls-panel.module.scss';
import { FullScreenHandle } from 'react-full-screen';
import { FullscreenButton } from '../fullscreen-button/fullscreen-button';
import { LangSwitcher } from '../lang-switcher/lang-switcher';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getSession } from '../../utils/session/get-session';

interface ControlsPanelProps {
  fullScreenHandle: FullScreenHandle;
}

export const ControlsPanel = ( { fullScreenHandle }: ControlsPanelProps ) => {
	const navigate = useNavigate();

	const { pathname, search } = useLocation();

	useEffect( () => {
		!pathname.includes( 'auth' ) && getSession().then( session => {
			!session && navigate( '/auth' );
			session
			&& ( !pathname.includes( 'game' ) && !pathname.includes( 'settings' ) )
			&& navigate( '/game' );
		} );
		// checking for the game room Id
		const roomId = search.match( /[^?]{9}/ );
		roomId && localStorage.setItem( 'roomId', `${roomId}` );
	}, [pathname, search] );

	return !pathname.includes( 'game' ) ? (
		<div className={styles['controls-panel']}>
			<LangSwitcher />
			<FullscreenButton fullScreenHandle={fullScreenHandle} />
		</div>
	) : (
		<></>
	);
};
