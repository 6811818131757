export const ru = {
	headers: {
		menu: 'Главное Меню',
		settings: 'Настройки'
	},
	buttons: {
		login: 'Войти',
		guest: 'Гость',
		signup: 'Регистрация',
		close: 'Закрыть',
		cancel: 'Отмена',
		yes: 'Да',
		no: 'Нет',
		mainMenu: 'Главное Меню',
		startGame: 'Начать',
		playAgain: 'Сыграть еще раз',
		loading: 'Загрузка',
		resetSettings: 'Сбросить',
		saveSettings: 'Сохранить'
	},
	placeholders: {
		username: 'Никнейм',
		email: 'Эл. Почта',
		password: 'Пароль',
		repeatPassword: 'Повторите Пароль'
	},
	settings: {
		lives: 'Количество жизней',
		time: 'Время матча',
		maxCubes: 'Максимальное количество блоков',
		minCubes: 'Минимальное количество блоков',
		durability: 'Максимальная прочность блоков',
		startSpeed: 'Начальная скорость шара',
		maxSpeed: 'Максимальная скорость шара',
		bonusChance: 'Шанс выпадения бонуса',
		bonusCount: 'Максимальное количество бонусов',
		debuffChance: 'Шанс выпадения дебаффа',
		debuffCount: 'Максимальное количество дебаффов',
		rebound: 'Коэффициент отскока шара',
		paddleSize: 'Размер платформы',
		speedBonus: 'Значение в процентах, на которое растет скорость шара',
		showBonus: 'Показывать/скрывать бонусы',
		advancedGen: 'Продвинутая генерация',
		difficulty: 'Сложность'
	},
	game: {
		matchmaking: 'Поиск Игры',
		pause: 'Игра Приостановлена',
		finishGame: 'Вы хотите закончить игру?'
	}
};
