import styles from './auth-page.module.scss';
import { ControlButton } from '../control-button/control-button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { ModalWindow } from '../modal-window/modal-window';
import { useTranslation } from 'react-i18next';
import { Client, Session } from '@heroiclabs/nakama-js';
import { v4 as uuid } from 'uuid';
import { getSession } from '../../utils/session/get-session';
import {AuthError} from '../../utils/errors/auth-error';

export const AuthPage = () => {
	const navigate = useNavigate();

	const { t } = useTranslation( ['buttons', 'placeholders'] );

	const [signUpModalOpen, setSignUpModalOpen] = useState( false );

	const formRef = useRef<{[elemKey: string] : HTMLInputElement | HTMLFormElement | null}>( {} );

	const client = new Client(
		undefined,
		window.location.hostname,
		undefined,
		true
	);

	useEffect( () => {
		getSession().then( session => {
			!!session && navigate( '/game' );
		} );
	}, [] );

	const getDeviceId = () => {
		if ( localStorage.getItem( 'deviceId' ) ) {
			return localStorage.getItem( 'deviceId' )!;
		}
		const id = uuid();
		localStorage.setItem( 'deviceId', id );
		return id;
	};

	const saveSession = ( session: Session ) => {
		localStorage.setItem( 'nakamaSession', JSON.stringify( session ) );
		// temp solution
		session.username && localStorage.setItem( 'username', session.username );
		navigate( '/game' );
	};

	const submitLogin = ( isGuestLogIn: boolean ) => {
		if ( isGuestLogIn ) {
			client
				.authenticateDevice( getDeviceId() )
				.then( ( session ) => saveSession( session ) )
				.catch( ( error ) => {
					if ( error.status ) {
						alert( `${error.status}: ${AuthError.UnknownError}` );
					} else {
						alert( AuthError.ConnectionFailed  );
					}
				} );
		} else {
			const {loginForm, loginEmail: email, loginPassword: password} = formRef.current;
			if ( !loginForm || !email || !password ) return;
			const loginFormIsValid = loginForm.checkValidity();
			if ( loginFormIsValid ) {
				client
					.authenticateEmail( email.value, password.value, false )
					.then( ( session ) => saveSession( session ) )
					.catch( ( error ) => {
						if ( error.status ) {
							switch ( error.status ) {
							case 404:
								alert( AuthError.NotFound );
								break;
							case 401:
								alert( AuthError.Unauthorized );
								break;
							default:
								alert( `${error.status}: ${AuthError.UnknownError}` );
							}
						} else {
							alert( AuthError.ConnectionFailed  );
						}
					} );
			} else {
				loginForm.reportValidity();
			}
		}
	};

	const submitSignup = () => {
		const {signupForm,
			signupEmail: email,
			signupUsername: username,
			signupPassword: password,
			signupRepeatPassword: repeatPassword
		} = formRef.current;
		if ( !signupForm || !email  || !username || !password || !repeatPassword ) return;
		repeatPassword.pattern = password.value;
		const signupFormIsValid = signupForm.checkValidity();
		if ( signupFormIsValid ) {
			client
				.authenticateEmail( email.value, password.value, true, username.value )
				.then( ( session ) => {
					if ( !session.created ) {
						alert( AuthError.Duplicate );
					} else {
						saveSession( session );
						setSignUpModalOpen( false );
					}
				} )
				.catch( ( error ) => {
					if ( error.status ) {
						switch ( error.status ) {
						case 409:
							alert( AuthError.UsernameTaken );
							break;
						case 401:
							alert( AuthError.Duplicate );
							break;
						default:
							alert( `${error.status}: ${AuthError.UnknownError}` );
						}
					} else {
						alert( AuthError.ConnectionFailed );
					}
				} );
		} else {
			signupForm.reportValidity();
		}
	};

	return (
		<div className={styles['auth-page']}>
			<div className={styles['auth-page__form']}>
				<form
					ref={( loginForm ) => {
						if ( !loginForm || !formRef ) return;
						formRef.current.loginForm = loginForm;
					}}
					id={'login-form'}
				>
					<input
						ref={( loginEmail ) => {
							if ( !loginEmail || !formRef ) return;
							formRef.current.loginEmail = loginEmail;
						}}
						className={styles['auth-page__form-input']}
						type={'email'}
						placeholder={t( 'email', { ns: 'placeholders' } )}
						autoComplete={'email'}
						id={'login-email'}
						required
					/>
					<input
						ref={( loginPassword ) => {
							if ( !loginPassword || !formRef ) return;
							formRef.current.loginPassword = loginPassword;
						}}
						className={styles['auth-page__form-input']}
						type={'password'}
						minLength={8}
						placeholder={t( 'password', { ns: 'placeholders' } )}
						autoComplete={'current-password'}
						id={'login-password'}
						required
					/>
				</form>
				<div className={styles['auth-page__form__controls']}>
					<ControlButton action={() => submitLogin( false )} text={t( 'login' )} />
					<ControlButton action={() => submitLogin( true )} text={t( 'guest' )} />
					<ControlButton
						action={() => setSignUpModalOpen( true )}
						text={t( 'signup' )}
					/>
				</div>
			</div>
			<ModalWindow open={signUpModalOpen}>
				<div className={styles['auth-page__form']}>
					<div
						className={styles['auth-page__form__close-icon']}
						onClick={() => setSignUpModalOpen( false )}
					/>
					<form
						ref={( signupForm ) => {
							if ( !signupForm || !formRef ) return;
							formRef.current.signupForm = signupForm;
						}}
						id={'signup-form'}
					>
						<input
							ref={( signupUsername ) => {
								if ( !signupUsername || !formRef ) return;
								formRef.current.signupUsername = signupUsername;
							}}
							className={styles['auth-page__form-input']}
							type={'text'}
							placeholder={t( 'username', { ns: 'placeholders' } )}
							autoComplete={'username'}
							id={'signup-username'}
							required
						/>
						<input
							ref={( signupEmail ) => {
								if ( !signupEmail || !formRef ) return;
								formRef.current.signupEmail = signupEmail;
							}}
							className={styles['auth-page__form-input']}
							type={'email'}
							placeholder={t( 'email', { ns: 'placeholders' } )}
							autoComplete={'email'}
							id={'signup-email'}
							required
						/>
						<input
							ref={( signupPassword ) => {
								if ( !signupPassword || !formRef ) return;
								formRef.current.signupPassword = signupPassword;
							}}
							className={styles['auth-page__form-input']}
							type={'password'}
							minLength={8}
							placeholder={t( 'password', { ns: 'placeholders' } )}
							autoComplete={'new-password'}
							id={'signup-password'}
							required
						/>
						<input
							ref={( signupRepeatPassword ) => {
								if ( !signupRepeatPassword || !formRef ) return;
								formRef.current.signupRepeatPassword = signupRepeatPassword;
							}}
							className={styles['auth-page__form-input']}
							type={'password'}
							minLength={8}
							placeholder={t( 'repeatPassword', { ns: 'placeholders' } )}
							autoComplete={'new-password'}
							id={'signup-repeat-password'}
							onChange={( { currentTarget } ) =>
								currentTarget.setCustomValidity( '' )
							}
							onInvalidCapture={( { currentTarget } ) =>
								currentTarget.setCustomValidity( 'Пароли не совпадают' )
							}
							required
						/>
					</form>
					<ControlButton action={() => submitSignup()} text={t( 'signup' )} />
				</div>
			</ModalWindow>
		</div>
	);
};
